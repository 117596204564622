<!--
 * @Description: 
 * @Author: 
 * @Date: 2021-03-11 11:55:44
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-23 11:40:59
-->
<template>
  <div id="app">
    <menuComopnent></menuComopnent>
    <div class="content"><router-view /></div>

    <div class="footer">
      <p>版权所有：湖南英富莱网络技术有限公司</p>
      <p>
        @2021Enfry Ltd.All rights reserved.
        <a
          style="color: white"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          >湘ICP备16017875号-1</a>
      </p>
    </div>
    <SideBar></SideBar>
  </div>
</template>
<script>
import menuComopnent from "@/components/Menu.vue";
import SideBar from "@/components/SideBar.vue";
export default {
  components: {
    menuComopnent,SideBar
  }
};
</script>

<style lang="scss">
html,
body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #efefef;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  border-radius: 5px;
}

.menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}
* {
  box-sizing: border-box;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}

html body {
  height: 100vh;
  margin: 0;
}

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
  height: 100%;
}
h1,
h2 {
  margin: 0;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 40px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: #fff;
  overflow-y: auto;
  .content {
    min-height: calc(100% - 116px);
    padding-top:60px;
  }
  .footer {
    overflow: hidden;
    padding-top: 20px;
    height: 100px;
    font-size: 12px;
    font-family: SimHei;
    color: #ffffff;
    line-height: 30px;
    background: #1f223a;
    p {
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }
}
.inner-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding-top: 66px;
}
@media (max-width: 750px) {
  .inner-title {
    font-size: 20px;
    padding-top: 30px;
    line-height: 20px;
  }
}
</style>
