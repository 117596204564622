<!--
 * @Author: Do not edit
 * @Date: 2021-07-22 15:00:17
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-18 14:51:41
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\contactUs.vue
-->
<template>
  <div class="contact-us" :class="{'contact-out':isComm}">
    <div class="inner-cent">
      <div class="col-info" v-if="isComm">
        <h6>留下您的联系方式</h6>
        <p>我们专业顾问将会尽快与您联系！</p>
      </div>
      <div class="col-form">
        <ul class="tabs">
          <li :class="{active:form.type==='企业'}" @click="changeType">企业</li>
          <li :class="{active:form.type==='个人'}" @click="changeType">个人</li>
        </ul>
        <el-form
        ref="form"
        :model="form"
        :rules="formRules"
        label-width="78px"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <el-form-item label="企业名称" prop="companyName" v-if="form.type==='企业'">
          <el-input
            v-model="form.companyName"
            maxlength="20"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="所在地" prop="address">
          <en-city
            v-model="form.address"
            placeholder=""
          ></en-city>
        </el-form-item>
        <el-form-item label="选择行业" prop="industry">
          <el-select v-model="form.industry" placeholder="">
            <el-option
            v-for="item in industryData"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="mobileNo">
          <el-input
            v-model="form.mobileNo"
            placeholder=""
          ></el-input>
        </el-form-item>
        </el-form>
        <span slot="footer">
          <div class="button" @click="submitForm('form')" v-loading="sendLoading">预约顾问咨询</div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import enCity from "../components/en-city";
import qs from "qs";

export default {
  name: "Jion",
  props:{
    hasbg: {
      type: Boolean,
      default: true
    }
  },
  components: {
    enCity
  },
  data() {
    return {
      isComm:true,
      sendLoading:false,
      form: {
        type:"企业",
        name: "",
        companyName: "",
        address:{},
        industry:"",
        mobileNo: ""
      },
      industryData:[
        {
          id:1,
          name:"食品饮料酒水"
        },
        {
          id:2,
          name:"餐饮美食"
        },
        {
          id:3,
          name:"生活服务"
        },
        {
          id:4,
          name:"家居装修建材"
        },
        {
          id:5,
          name:"婚恋"
        },
        {
          id:6,
          name:"教育"
        },
        {
          id:7,
          name:"金融"
        },
        {
          id:8,
          name:"房地产"
        },
        {
          id:9,
          name:"招商加盟服务"
        },
        {
          id:10,
          name:"综合电商平台"
        },
        {
          id:11,
          name:"旅游"
        },
        {
          id:12,
          name:"游戏"
        },
        {
          id:13,
          name:"汽车"
        },
        {
          id:14,
          name:"其他"
        }
      ],
      formRules: {
        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur"
          }
        ],
        companyName: [
          {
            required: true,
            message: "请输入您的公司名称",
            trigger: "blur"
          }
        ],
        address: [
          {
            required: true,
            message: "请选择您的所在地"
          }
        ],
        industry: [
          {
            required: true,
            message: "请选择您的行业"
          }
        ],
        mobileNo: [
          {
            required: true,
            message: "请输入您的联系号码",
            trigger: "blur"
          },
          {
            pattern: /^1\d{10}$/,
            message: "手机号格式不正确",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted(){
    this.isComm = this.hasbg;
  },
  methods: {
    changeType(){
      this.form.type = this.form.type==="企业"?"个人":"企业";
    },
    /**
     * @description: 提交确定
     * @return:
     */
    async submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.sendLoading = true;
          let formData = Object.assign({}, this.form);
          formData.address = formData.address.cityName;
          this.$axios
            .post("/api/saveSuggest.app", qs.stringify(formData) ,{
              headers:{
                "Content-Type":"application/x-www-form-urlencoded; charset=UTF-8"
              }
            }).then(response => {
              console.log("response", response);
              this.$message({
                type: "success",
                message: "提交成功，3s后返回首页",
                offset: 100
              });
              setTimeout(() => {
                this.$router.push({ path: "/home" });
                location.reload();
              }, 3000);
              // if (response.status === 200) {
              // }
            })
            .catch(error => {
              console.log("error", error);
            });
        }
        return false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .input-area{
  width:100%!important;
  position: relative;
}
/deep/ .el-select{
  width:100%;
}
/deep/ .en-city .en-city-panel{
  left:0;
  width:509px;
  top:46px;
}
/deep/ .button-area{
  .button{
    display: none!important;
  }
  .confirm {
    display: block!important;
  }
}
/deep/ .cn-picker-input{
  width:100%!important;
  padding-left:100px;
  height:46px;
  line-height:46px;
}
.tabs{
    display:flex;
    padding: 0;
    margin: 0;
    overflow: hidden;
  li{
    list-style: none;
    flex:1;
    height:76px;
    display: inline-block;
    line-height:76px;
    cursor: pointer;
    text-align: center;
    color: #333;
      background:#f1f6ff;
      border-bottom:1px solid #dce5ec;
      position: relative;
      font-size:24px;
      &::before{
        content:"";
        position: absolute;
        top:0;
        left: -40px;
        border-width: 76px 40px;
        border-style: solid;
        border-color: #f1f6ff transparent transparent transparent;
      }
      &::after{
        content:"";
        position: absolute;
        top:0;
        right: -40px;
        border-width: 76px 40px;
        border-style: solid;
        border-color: #f1f6ff transparent transparent transparent;
      }
    &.active{
      background:#fff;
      border:0;
    }
    &:first-child{
      &.active{
        &::before{
          display: none;
        }
      }
    }
    &:last-child{
      &.active{
        &::after{
          display: none;
        }
      }
    }
  }
}
/deep/ .el-form-item{
  position: relative;
}
/deep/ .el-form--label-top .el-form-item__label{
  position: absolute;
  top: 0;
  left:16px;
  font-size:16px;
  line-height:46px;
  z-index:2;
}
/deep/ .el-input__inner{
  padding-left: 100px;
  height:46px;
  line-height:46px;
}
.el-form{
  margin:30px 20px;
}
.button{
  width:500px;
  height:50px;
  line-height:50px;
  color:#fff;
  background:#0183de;
  text-align: center;
  margin:0 auto;
  border-radius:30px;
  font-size:18px;
  cursor: pointer;
  margin-bottom: 50px;
}
.col-form{
  border: 1px solid #dce5ec;
  background:#fff;
  margin-top:50px;
  z-index:10;
  border-radius:5px;
}
.col-form{
  width: 100%;
}
.col-info{
  position: absolute;
  left:50%;
  top:100px;
  margin-left:-650px;
  h6{
    font-size:30px;
    margin: 0;
    font-weight: normal;
  }
  p{
    font-size:22px;
  }
}
.contact-out{
  position: relative;
  .col-form{
    width:550px;
    left:50%;
    margin-right:-330px;
    position: absolute;
  }
  .inner-cent{
    width:540px;
    height:675px;
    text-align:left;
  }
  &::after{
    content: "";
    width:100%;
    height:353px;
    background: url("~@/assets/images/contact-bg.png") no-repeat bottom left #f1f6ff;
    display: block;
    position: absolute;
    bottom: 36px;
    z-index:1;
  }
}

@media (max-width: 750px) {
  .contact-out .inner-cent{
    width: auto;
    height: auto;
  }
  .col-info{
    position: inherit;
    left: auto;
    top: auto;
    margin-left: 0;
    width: 90%;
    margin: 30px auto;
    h6{
      font-size: 22px;
      font-weight: bold;
    }
    p{
      font-size:18px;
    }
  }
  .tabs li{
    font-size:18px;
    font-weight: bold;
  }
  .contact-out .col-form{
    width:90%;
    border: 1px solid #dce5ec;
    background: #fff;
    position: inherit;
    left: auto;
    margin: 0 auto;
    z-index: 10;
  }
  .contact-out::after{
    background:none;
    display: none;
  }
  .button{
    width:90%;
    margin:0 auto 30px;
  }
}
</style>
