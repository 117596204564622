/*
 * @Description:
 * @Author:
 * @Date: 2021-03-11 10:48:19
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-18 14:46:23
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

import {
  Menu,
  Submenu,
  MenuItem,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Message,
  Tooltip,
  Loading,
  Collapse,
  CollapseItem
} from "element-ui";
import "animate.css";

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(Tooltip);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(CollapseItem);
Vue.use(Loading.directive);

Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;

let baseURL = "/api";
if (process.env.NODE_ENV === "production") {
  baseURL =  "https://www.enfry.com/";
}
axios.defaults.baseURL = baseURL;
axios.defaults.headers = {
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  Accept: "application/json;charset=UTF-8"
}
Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");
