/*
 * @Author: Do not edit
 * @Date: 2021-03-16 09:31:58
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-11 10:38:41
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/details",
    name: "Details",
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/Details.vue")
  },
  {
    path: "/trend",
    name: "trend",
    component: () => import(/* webpackChunkName: "case" */ "../views/trend.vue")
  },
  {
    path: "/case",
    name: "Case",
    component: () => import(/* webpackChunkName: "case" */ "../views/Case.vue")
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () =>
      import(/* webpackChunkName: "cooperation" */ "../views/Cooperation.vue")
  },
  {
    path: "/service",
    name: "Service",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/Service.vue")
  },
  {
    path: "/ServiceTwo",
    name: "ServiceTwo",
    component: () =>
      import(/* webpackChunkName: "service" */ "../views/ServiceTwo.vue")
  },
  {
    path: "/Download",
    name: "Download",
    component: () => import(/* webpackChunkName: "Download" */ "../views/download.vue")
  },
  {
    path: "/Article",
    name: "Article",
    component: () => import(/* webpackChunkName: "Article" */ "../views/article.vue")
  },
  {
    path: "/join",
    name: "Join",
    component: () => import(/* webpackChunkName: "join" */ "../views/Join.vue")
  },
  {
    path: "/Contact",
    name: "Contact",
    component: () => import(/* webpackChunkName: "Contact" */ "../views/Contact.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0
    };
  },
  routes
});

export default router;

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
