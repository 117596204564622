<!--
 * @Author: Do not edit
 * @Date: 2021-07-22 14:04:36
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-10 16:44:34
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\cooperateList.vue
-->
<template>
  <div class="cooperate-list">
    <dl v-for="(item,index) in logoList" :key="index">
      <dt><img :src="item.src" :alt="item.name" /></dt>
      <!-- <dd class="col-name">{{ item.name }}</dd> -->
    </dl>
    <!-- <dl class="see-more" v-if="index" @click="seeMore">查看更多<br/>+</dl> -->
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: Number,
      default: 0
    },
    index: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listAll: [
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-dacheng.png"),
          name: "大成律所"
        },
        {
          id: 2,
          src: require("@/assets/images/cooperation/logo-51talk.png"),
          name: "51talk "
        },
        {
          id: 3,
          src: require("@/assets/images/cooperation/logo-zhilian.png"),
          name: "智联招聘"
        },
        {
          id: 4,
          src: require("@/assets/images/cooperation/logo-wanjiang.png"),
          name: "万江科技"
        },
        {
          id: 5,
          src: require("@/assets/images/cooperation/logo-boyi.png"),
          name: "博益气动"
        },
        {
          id: 6,
          src: require("@/assets/images/cooperation/logo-jingxiang.png"),
          name: "祥锦车行"
        },
        {
          id: 7,
          src: require("@/assets/images/cooperation/logo-yinxiang.png"),
          name: "印象笔记"
        },
        {
          id: 8,
          src: require("@/assets/images/cooperation/logo-meinian.png"),
          name: "美年大健康"
        },
        {
          id: 9,
          src: require("@/assets/images/cooperation/logo-kangsai.png"),
          name: "赛康医疗"
        },
        {
          id: 10,
          src: require("@/assets/images/cooperation/logo-dianxin.png"),
          name: "广东电信"
        },
        {
          id: 11,
          src: require("@/assets/images/cooperation/logo-zhongchuang.png"),
          name: "中创集团"
        },
        {
          id: 12,
          src: require("@/assets/images/cooperation/logo-landu.png"),
          name: "兰度生物"
        },
        {
          id: 13,
          src: require("@/assets/images/cooperation/logo-yujiahui.png"),
          name: "御家汇"
        },
        {
          id: 14,
          src: require("@/assets/images/cooperation/logo-dachengfang.png"),
          name: "大成方略"
        },
        {
          id: 15,
          src: require("@/assets/images/cooperation/logo-hunan.png"),
          name: "湖南卫视"
        }
      ],
      list1:[
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-bimawei.png"),
          name: "毕马威"
        },
        {
          id: 2,
          src: require("@/assets/images/cooperation/logo-yongan.png"),
          name: "安永"
        },
        {
          id: 3,
          src: require("@/assets/images/cooperation/logo-xinyong.png"),
          name: "信用中和"
        },
        {
          id: 4,
          src: require("@/assets/images/cooperation/logo-lixin.png"),
          name: "立信"
        },
        {
          id: 5,
          src: require("@/assets/images/cooperation/logo-ibm.png"),
          name: "IBM"
        },
        {
          id: 6,
          src: require("@/assets/images/cooperation/logo-youcai.png"),
          name: "东方优财"
        },
        {
          id: 7,
          src: require("@/assets/images/cooperation/logo-weilai.png"),
          name: "未来数科"
        }
      ],
      list2:[
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-boya.png"),
          name: "博雅智享"
        },
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-huatai.png"),
          name: "华钛科技"
        }
      ],
      list3:[
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-zhongcaixie.png"),
          name: "中财协"
        },
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-zhonglvxie.png"),
          name: "中律协"
        }
      ],
      list4:[
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-caixin.png"),
          name: "财信小贷"
        },
        {
          id: 1,
          src: require("@/assets/images/cooperation/logo-guangfa.png"),
          name: "广发银行"
        }
      ]
    };
  },
  computed: {
    logoList(){
      let lis = [];
      switch(this.type){
        case 0:
          lis = this.listAll;
          break
        case 1:
          lis = this.list1;
          break
        case 2:
          lis = this.list2;
          break
        case 3:
          lis = this.list3;
          break
        case 4:
          lis = this.list4;
          break
        default:
          break
      }
      return lis;
    }
  },
  methods: {
    seeMore(){
      this.$router.push({ name: "Cooperation"})
    }
  }
};
</script>
<style lang="scss" scoped>
dd{
  margin:0
}
.cooperate-list {
  text-align:left;
  dl {
    width: 225px;
    height: 110px;
    background: #fff;
    border-radius: 5px;
    display: inline-block;
    margin:10px 5px;
    border-radius:5px;
    overflow:hidden;
    cursor:pointer;
    img{
      width:100%;
      margin-top:5px;
    }
  }
  .see-more{
    border:1px solid #0183de;
    text-align:center;
    padding-top:46px;
    font-size:22px;
    line-height:32px;
    color:#333;
  }
  .col-name {
    background: #0183de;
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    border-radius: 0 0 5px 5px;
  }
}
@media (max-width: 750px) {
  .cooperate-list{
    dl{
      width: 45%;
      margin: 10px 0 0 10px;
      height: auto;
      img{
        width:100%;
      }
    }
    .see-more{
      font-size:20px;
      padding-top:20px;
      padding-bottom:10px;
    }
  } 
}
</style>
