<!--
 * @Author: Do not edit
 * @Date: 2021-08-09 10:28:56
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-09 15:31:45
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\components\SideBar.vue
-->
<template>
  <div class="sideBar">
  <ul id="sideBar-cent">
    <li class="order" title="en+需求单"> 
      <a href="https://wj.qq.com/s/2100308/0f12" target="_blank">
        <i><img src="@/assets/images/side-icon4.png" class="imgs" /></i>
        <span>需求单</span>
      </a>
    </li>
    <li class="wchart" title="微信公众号" @click="codeEve">
      <i><img src="@/assets/images/side-icon3.png" class="imgs" /></i>
      <span>微信公众号</span>
      <div class="floating-window" v-if="showCode">
        <div class="wx-bubble">
          <p>微信公众号</p>
          <div class="wx-img">
            <img src="@/assets/images/code-public2.png" class="imgs" />
          </div>
        </div>
      </div>
    </li>
    <li class="message" title="联系我们"  @click="TelEve"> 
      <i><img src="@/assets/images/side-icon2.png" class="imgs" /></i>
      <span>咨询电话</span>
      <div class="floating-window" v-if="showTel">
        <div class="msg-bubble">
          <div class="msg-conent suggest-form">
            <div class="msg-tel">
              <i class="icon-tel"></i>
              <div class="txt-tel">咨询电话：<b>400-617-9001</b></div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="top" @click="backTop" title="返回顶部">
      <i><img src="@/assets/images/side-icon1.png" class="imgs" /></i>
      <span>返回顶部</span>
    </li>
  </ul>
  </div>
</template>
<script>
export default {
  name: "Menu",
  beforeRouteEnter() {
  },
  computed: {
  },
  data() {
    return {
      showCode:false,
      showTel:false
    };
  
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },  
  watch: {
  },
  methods: {
    codeEve(){
      this.showTel = false
      this.showCode = !this.showCode
    },
    TelEve(){
      this.showCode = false
      this.showTel = !this.showTel
    },
    backTop(){
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 0) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    }  
  }
};
  
</script>
<style lang="scss" scoped>
.sideBar{
  position: fixed;
  right: 16px;
  top: 60%;
  z-index: 99;
  color:#333;
  li{
    position:relative;
    cursor: pointer;
    background: rgba(0,0,0,.7);
    width: 36px;
    height: 36px;
    text-align:center;
    line-height:36px;
    margin-bottom: 5px;
    border-radius: 5px;
    list-style:none;
  }
  li i img{font-size: 0;
    width:22px;
    margin-top:7px;
  }
  li span{font-size: 0;
    display: none;
  }
  .side-cur .floating-window{
    display: block;
  }
  li .floating-window{
    position: absolute;
    top:0;
    right: 48px;
  }
  .wchart .wx-bubble{
    position: relative;
    padding:10px 25px;
    height: auto;
    background: #fff;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow:-2px 2px 8px #929191;
    -webkit-box-shadow:-2px 2px 8px #929191;
    box-shadow:-2px 2px 8px #929191;
  }
  .wchart .wx-bubble::before{
    position: absolute;
    width: 10px;
    height: 28px;
    left: 100%;
    top: 5px;
  }
  .wchart .wx-bubble p{
    font-size: 12px;
    line-height: 14px;
  }
  .wchart .wx-img{height: 150px;
  width: 150px;
  }
  .wchart .wx-img img{
    height: 100%;
    width: 100%;
  }
  .message .msg-conent{
    position: relative;
    width:230px;
    padding:0 18px 0px 18px;
    background: #fff;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow:-2px 2px 8px #929191;
    -webkit-box-shadow:-2px 2px 8px #929191;
    box-shadow:-2px 2px 8px #929191;
  }
  .message .msg-conent::before{
    position: absolute;
    width: 10px;
    height: 28px;
    left: 100%;
    top: 5px;
  }
  .message .msg-tel{
    height: 40px;
    line-height: 40px;
    border-bottom: solid 1px #e8e8e8;
    text-align: left;
  }
  .message .msg-tel .icon-tel{
    font-family:"enfont";
    font-style:normal;
    font-size: 22px;
    color: #49bef3;
    margin-right:10px;
    display: inline-block;
    vertical-align: middle;
  }
  .message .msg-tel .txt-tel{
    display: inline-block;
    vertical-align: middle;
    font-size:12px;
  }
  .message .msg-inp-tel input{
    margin-top:14px;
    height:32px;
    line-height:32px;
    padding:0 12px;
    border:solid 1px #e8e8e8;
    border-radius: 5px;
    width: 340px;
    font-size: 12px;
  }
  .message .msg-inp-tel input:focus{
    border: 1px solid #49bef3;
    outline: none;
  }
  .message .msg-txt-suggest textarea{
    margin-top:14px;
    line-height:32px;
    width: 340px;
    font-size: 12px;
    padding:0 12px;
    border-radius: 5px;
    border:solid 1px #e8e8e8;
    resize: none;
    font-family:'microsoft yahei'}
  .message .msg-txt-suggest textarea:focus{
    border: 1px solid #49bef3;
    outline: none;
  }
  .message .msg-btn button{
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #49bef3;
    border:none;
    border-radius: 5px;
  }
  .message .msg-btn button:focus{
    border: none;
    outline: none;
  }
  .order a{
    line-height: 36px;
  }
 }

</style>
