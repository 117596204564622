<!--
 * @Author: Louis
 * @Date: 2019-08-13 14:28:36
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-06 14:18:55
 * @Description: 城市选择输入框
 -->
<template>
<!-- v-click-outside="clickOutSide" -->
  <div
    class="en-city"
  >
<!--    <div-->
<!--      v-if="showPanel"-->
<!--      class="en-city-arrow"-->
<!--    >-->
<!--    </div>-->
    <div class="en-city-container">
      <div
        v-if="tipsLabel !== ''"
        class="tips-label"
      >
        {{ tipsLabel }}
      </div>
      <en-icon
        v-if="showIcon"
        name="jiudiandizhi-wodehangcheng"
        class="location-icon"
      ></en-icon>
      <el-input
        v-model="valueInfo.enCityName"
        :class="{'with-tips': tipsLabel !== '','focus': focusSearch || focusInput,'show-icon':showIcon}"
        readonly="readonly"
        :placeholder="placeholder"
        @blur="blurInputEv"
        @click.stop
        @focus="currentFocus"
      >
      </el-input>
    </div>
    <div
      v-if="focusInput ? true : !focusInput && focusSearch "
      class="en-city-panel"
    >
      <el-autocomplete
        v-model="filter"
        :clearable="true"
        placeholder="可直接输入拼音或首字母"
        :trigger-on-focus="false"
        class="search-input"
        :fetch-suggestions="querySearch"
        @blur="blurSearch"
        @click.stop.native.prevent="focusSearchInput"
        @select="selectCityDataInfo"
      >
      </el-autocomplete>
      <!-- 快捷城市首字母列表 -->
      <div class="en-city-tab">
        <div
          v-for="item in tabList"
          :key="item.id"
          class="en-city-tab-item"
          :class="{'active':activeTab.id === item.id}"
          @click="changeActive(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <!-- 热门城市列表呈现区域 -->
      <div
        v-if="activeTab.id === 'hot'"
        class="en-hot-city-list"
      >
        <div
          v-for="item in hotCity"
          :key="item.id"
          :class="{'active':item.id === valueInfo.id}"
          class="en-hot-city-item"
          @click="selectCityDataInfo(item)"
        >
          {{ item.enCityName }}
        </div>
      </div>
      <!-- 基础城市列表呈现区域 -->
      <div
        v-if="activeTab.id !== 'hot'"
        class="en-city-arr"
      >
        <div
          v-for="item in cityList"
          :key="item.firstPy"
          class="en-city-arr-item"
        >
          <div class="en-city-arr-item-name">
            {{ item.firstPy }}
          </div>
          <div class="en-city-arr-list">
            <div
              v-for="el in item.cityArr"
              :key="el.id"
              class="city-list-item"
              :class="{'active':el.id === valueInfo.id}"
              @click="selectCityDataInfo(el)"
            >
              {{ el.enCityName.length > 5 ? el.enCityName.substring(0,5) + "..." : el.enCityName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cityJSON from "./city.json";

export default {
  name: "EnCity",
  props: {
    // 城市类型->酒店或者机票
    type: {
      type: String,
      default: "hotel",
      validator(value) {
        return ["flight", "hotel"].indexOf(value) > -1;
      }
    },
    // 图标
    showIcon: {
      type: Boolean,
      default: false
    },
    // 搜索提示框提示文本
    tipsLabel: {
      type: String,
      default: ""
    },
    // 输入框提示文本
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => ({})
    }
    // showPanel: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      cityDataList: [],
      dataInfo: "",
      cityList: [],
      hotCity: [],
      tabList: [],
      activeTab: {},
      filter: "",
      valueInfo: {},
      focusSearch: false,
      focusInput: false,
      showPanel: false
    };
  },
  watch: {
    /**
     * @description 展示选择面板，并选中对应城市所在的Panel
     * @param nVal
     */
    focusInput(nVal) {
      if (nVal && this.valueInfo.firstPy) {
        this.tabList.forEach((item) => {
          if (item.id.indexOf(this.valueInfo.firstPy.toUpperCase()) !== -1) {
            this.changeActive(item);
          }
        });
      }
    },
    value() {
      this.valueInfo = { ...this.value };
    }
  },
  mounted() {
    this.valueInfo = { ...this.value };
    this.setCityDataList();
  },
  methods: {
    onClickOutside() {
      this.clickOutSide();
    },
    /**
     * 设置城市列表
     */
    setCityListArrInfo() {
      this.cityList = [];
      if (this.activeTab.id !== "hot") {
        this.activeTab.list.forEach((item) => {
          const obj = {
            firstPy: item,
            cityArr: []
          };
          this.cityList.push(obj);
        });
        this.setBasicCityDataList();
      } else {
        this.setHotCityDataList();
      }
    },
    /**
     * @description 设置热门城市列表
     */
    setHotCityDataList() {
      this.hotCity = [];
      this.cityDataList.forEach((item) => {
        if (item.isHot === 0) {
          this.hotCity.push(item);
        }
      });
    },
    /**
     * @description 设置基础城市数据列表
     */
    setBasicCityDataList() {
      this.cityDataList.forEach((item) => {
        this.cityList.forEach((cItem) => {
          if (item.firstPy.toUpperCase() === cItem.firstPy) {
            cItem.cityArr.push(item);
          }
        });
      });
    },
    /**
     * @description 设置Tabs数据
     */
    setTabListData() {
      this.tabList = [
        {
          id: "hot",
          list: [],
          label: "热门"
        },
        {
          id: "ABCDE",
          label: "ABCDE",
          list: [
            "A",
            "B",
            "C",
            "D",
            "E"
          ]
        },
        {
          id: "KLMN",
          label: "KLMN",
          list: [
            "K",
            "L",
            "M",
            "N"
          ]
        },
        {
          id: "FGHJ",
          label: "FGHJ",
          list: [
            "F",
            "G",
            "H",
            "J"
          ]
        },
        {
          id: "PQRST",
          label: "PQRST",
          list: [
            "P",
            "Q",
            "R",
            "S",
            "T"
          ]
        },
        {
          id: "WXYZ",
          label: "WXTZ",
          list: [
            "W",
            "X",
            "Y",
            "Z"
          ]
        }
      ];
      // eslint-disable-next-line prefer-destructuring
      this.activeTab = this.tabList[0];
      this.setCityListArrInfo();
    },
    /**
     * @description 根据不同的类型进行数据归类
     */
    setCityDataList() {
      this.cityDataList = [];
      cityJSON.forEach((item) => {
        if (this.type === "flight" && item.partnerCode === "P01") {
          this.cityDataList.push(item);
        }
        if (this.type === "hotel" && item.partnerCode === "H02") {
          this.cityDataList.push(item);
        }
      });
      this.setTabListData();
    },
    /**
     * @description 更改选中的城市
     * @param dataInfo
     */
    changeActive(dataInfo) {
      this.activeTab = dataInfo;
      this.setCityListArrInfo();
    },
    /**
     * @description 选中对应的城市
     * @param dataInfo
     */
    selectCityDataInfo(dataInfo) {
      this.$emit("input", dataInfo);
      this.valueInfo = dataInfo;
      this.clickOutSide();
    },
    /**
     * @description 城市搜索
     * @param keywords
     * @param cb
     */
    querySearch(keywords, cb) {
      this.focusSearch = true;
      const { cityDataList } = this;
      const results = keywords ? cityDataList.filter(this.createFilter(keywords)) : cityDataList;
      cb(results);
    },
    /**
     * @description 搜索框聚焦
     */
    focusSearchInput() {
      this.focusSearch = true;
    },
    /**
     * @description 搜索框离开焦点
     */
    blurSearch() {
      console.log("移开焦点");
      setTimeout(() => {
        this.focusSearch = false;
      }, 300);
    },
    /**
     * @description 更改选中
     * @param keywords
     * @return {Function}
     */
    createFilter(keywords) {
      return (item) => {
        if (item.pinYin.toUpperCase().indexOf(keywords.toUpperCase()) !== -1 || item.firstPy.toUpperCase().indexOf(keywords.toUpperCase()) !== -1 || item.enCityName.indexOf(keywords) !== -1) {
          item.value = item.enCityName;
          return item;
        }
        return null;
      };
    },
    /**
     * @description 点击外部，隐藏面板
     */
    clickOutSide() {
      this.focusSearch = false;
      this.focusInput = false;
    },
    /**
     * @description 设置聚焦
     */
    currentFocus() {
      this.$emit("focus");
      this.focusInput = true;
      this.focusSearch = true;
    },
    /**
     * @description 输入框失去焦点触发
     */
    blurInputEv() {
      setTimeout(() => {
        this.focusInput = false;
      }, 300);
    }
  }
};
</script>

<style lang="scss" scoped>
.en-city {
  user-select: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  .location-icon {
    color: #d2d5da;
    width: 18px;
    position: absolute;
    left: 7px;
    z-index: 3;
    top: -1px;
  }
  .en-city-container /deep/ {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    .el-input {
      display: flex;
      justify-content: flex-start;
      &.show-icon {
        .el-input__inner {
          padding-left: 28px;
        }
      }
      &.focus {
        .el-input__inner {
          border-color: #4694df;
        }
      }
    }
  }

  .en-city-panel {
    width: 530px;
    z-index: 3;
    left: -58px;
    top: 45px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid rgba(232, 236, 242, 1);

    .el-autocomplete /deep/ {
      width: 100%;

      .el-input__inner {
        padding: 0 15px !important;
        border-radius: 4px 4px 0 0 !important;
      }
    }

    /* 城市切换页签样式控制 */
    .en-city-tab {
      display: flex;
      justify-content: flex-start;
      background-color: #f6fafd;
      padding: 0 10px;
      border-bottom: 1px solid #e8ecf2;

      .en-city-tab-item {
        text-align: center !important;
        min-width: 40px;
        height: 40px;
        line-height: 40px !important;
        text-align: left;
        font-size: 12px;
        color: #333333;
        font-weight: 400;
        line-height: 12px;
        border-bottom: 2px solid transparent;
        margin-right: 44px;
        cursor: pointer;

        &.active {
          color: #4694df;
          font-weight: bold;
          border-bottom: 2px solid #4694df;
        }
      }
    }

    /* 热门城市样式控制 */
    .en-hot-city-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 14px 12px;

      .en-hot-city-item {
        width: 84px;
        padding: 0 6px;
        line-height: 26px;
        text-align: left;
        cursor: pointer;
        height: 26px;
        font-size: 12px;
        color: #333333;

        &.active {
          background-color: #4694df;
          color: #ffffff;

          &:hover {
            color: #ffffff;
          }
        }

        &:hover {
          color: #4694df;
        }
      }
    }

    /* 非热门城市样式控制 */
    .en-city-arr {
      padding: 14px 12px;

      .en-city-arr-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .en-city-arr-item-name {
          width: 40px;
          text-align: center;
          color: #4694df;
          line-height: 26px;
          margin-right: 44px;
        }

        .en-city-arr-list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: calc(100% - 84px);

          .city-list-item {
            width: 80px;
            padding-left: 4px;
            margin-left: -4px;
            margin-right: 4px;
            text-align: left;
            line-height: 26px;
            color: #333333;
            cursor: pointer;
            height: 26px;
            font-size: 12px;

            &.active {
              background-color: #4694df;
              color: #ffffff;

              &:hover {
                color: #ffffff;
              }
            }

            &:hover {
              color: #4694df;
            }
          }
        }
      }
    }
  }
}
  .en-city-panel {
    border: 1px solid #E8ECF2;
    box-shadow:0px 3px 3px 0px rgba(232,232,232,0.75);
    /deep/ {
      .search-input {
        input {
          border: 0;
        }
      }
    }
  }
@media (max-width: 750px) {
  .en-city .en-city-panel{
    width:100%!important;
  }
  .en-city .en-city-panel .en-city-tab .en-city-tab-item{
    margin-right: 3px;
  }
  .en-city .en-city-panel .en-city-arr{
    max-height:200px;
    overflow-y: auto;
  }
  .en-city .en-city-panel .en-hot-city-list .en-hot-city-item{
    width: 80px;
  }
  .en-city .en-city-panel .en-city-arr .en-city-arr-item .en-city-arr-item-name{
    margin-right:5px;
  }
  .en-city .en-city-panel .en-city-arr .en-city-arr-item .en-city-arr-list{
    width: calc(100% - 30px);
  }
}
</style>
