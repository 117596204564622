<!--
 * @Description: 
 * @Author: 
 * @Date: 2021-03-11 11:55:44
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-23 14:12:54
-->
<template>
  <div class="home">
    <div class="seciton1 swiper-container">
      <swiper
        class="swiper-wrapper"
        ref="mySwiper"
        :options="swiperOptions"
        :auto-update="true"
        :auto-destroy="true"
        :delete-instance-on-destroy="true"
        :cleanup-styles-on-destroy="true"
      >
        <swiper-slide>
          <div
            class="swiper-data swiper-data1"
            style="background-color:#0494e0"
          >
            <img
              src="@/assets/images/home/banner1-img.png"
              class="imgs wow bounceInRight"
            />
            <div
              class="swipertxt wow bounceInLeft"
              data-wow-delay="0.3s"
              title="1"
            >
              <h1 class="maintit">en+数字化</h1>
              <p class="subtit">1个数字化平台，多种数字化能力</p>
              <a class="home-contact home-video" href="/Article?id=shipin5" title="观看视频"
                >观看视频</a>
              <a class="home-contact" href="/Contact" title="联系我们"
                >联系我们</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-data swiper-data2" >
            <img
              src="@/assets/images/home/banner2-img.png"
              class="imgs wow bounceInLef"
            />
            <div
              class="swipertxt wow bounceInRight"
              data-wow-delay="0.3s"
              title="1"
            >
              <h1 class="maintit">en+数字化</h1>
              <p class="subtit">为企业搭建业财融合枢纽系统</p>
              <a class="home-contact home-video" href="/Article?id=shipin5" title="观看视频"
                >观看视频</a>
              <a class="home-contact" href="/Contact" title="联系我们"
                >联系我们</a
              >
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="section-about">
      <div class="inner-title wow fadeInUp">
        <h3>我们是谁？</h3>
        <p>为<span style="color:#0183de">企业数字化</span>提供<span style="color:#0183de">咨询+落地</span>服务的赋能型公司</p>
      </div>
      <div class="cent">
        <dl class="wow bounceInLeft">
          <dd class="img">
            <img src="@/assets/images/home/about1.png" class="imgs" />
          </dd>
          <dd class="txt">
            <h6>我们的使命</h6>
            <p>最大化企业数据价值</p>
          </dd>
        </dl>
        <dl class="wow bounceInRight">
          <dd class="img">
            <img src="@/assets/images/home/about2.png" class="imgs" />
          </dd>
          <dd class="txt">
            <h6>我们的愿景</h6>
            <p>引领数字化中国的发展</p>
          </dd>
        </dl>
      </div>
    </div>
    <div class="seciton-advantage">
      <div class="inner-title">
        <h3>我们的优势</h3>
      </div>
      <div class="cent">
        <el-tabs v-model="active3">
          <el-tab-pane name="0">
            <div slot="label" class="tabhead"><h3>服务优势</h3></div>
            <div class="cent">
              <div class="tag-imgs  wow fadeInUp">
                <dl class="blue-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage1-1.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>专业化</h6></dd>
                </dl>
                <dl class="green-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage1-2.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>多元化</h6></dd>
                </dl>
                <dl class="orange-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage1-3.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>硬软结合</h6></dd>
                </dl>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="1">
            <div slot="label" class="tabhead"><h3>性价比优势</h3></div>
            <div class="cent">
              <div class="tag-imgs  wow fadeInUp">
                <dl class="blue-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage2-1.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>方案性价比</h6></dd>
                </dl>
                <dl class="green-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage2-2.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>IT投入性价比</h6></dd>
                </dl>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div slot="label" class="tabhead"><h3>敏捷与扩展</h3></div>
            <div class="cent">
              <div class="tag-imgs  wow fadeInUp">
                <dl class="blue-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage3-1.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>敏捷适应</h6></dd>
                </dl>
                <dl class="green-bg">
                  <dd class="img">
                    <img src="@/assets/images/home/advantage3-2.png" class="imgs" />
                  </dd>
                  <dd class="txts"><h6>无限扩展</h6></dd>
                </dl>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="section-service">
      <div class="inner-title wow fadeInUp">
        <h3>我们的服务</h3>
      </div>
      <div class="cent wow fadeInUp">
        <el-tabs v-model="active2">
          <el-tab-pane name="0">
            <div slot="label" class="tabhead">
              <h3>数字化服务能力</h3>
              <p>双轮驱动</p>
            </div>
            <div class="cent headcent1"  style="height:650px">
              <div class="service-tabs">
                <ul class="shead">
                  <li :class="{ active: showS1==='1'}"  @click="changeImg">01/方案是灵魂</li>
                  <li :class="{ active: showS1==='2'}"  @click="changeImg">02/工具是载体</li>
                </ul>
                <div class="sbtn">
                  <span class="prev" @click="changeImg"></span>
                  <span class="next" @click="changeImg"></span>
                </div>
                <div class="scent">
                  <div class="s-item" v-if="showS1==='1'">
                    <el-tooltip class="lis" v-for="item in service1" :key="item.id" effect="dark" :content="item.content" :class="classItem(item.id)" placement="top-start">
                      <span>{{item.name}}</span>
                    </el-tooltip>
                    <img
                      src="@/assets/images/home/service1-1.png"
                      class="imgs wow bounceInRight"
                    />
                  </div>
                  <div class="s-item" v-if="showS1==='2'">
                    <span class="lis" v-for="item in service2" :key="item.id" effect="dark" :class="tclassItem(item.id)">
                      {{item.name}}
                    </span>
                    <img
                      src="@/assets/images/home/service1-2.png"
                      class="imgs imgs2 wow bounceInRight"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane name="1">
            <div slot="label" class="tabhead">
              <h3>数字化服务体系</h3>
              <p>服务铁三角</p>
            </div>
            <div class="cent headcent2">
              <img
                src="@/assets/images/home/service2-1.png"
                class="txtsbg"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="section-cooperate">
      <div class="inner-title wow fadeInUp">
        <h3>我们的客户</h3>
        <p>超过5000+企业成功部署</p>
      </div>
      <div class="cent wow fadeInUp">
        <cooperateList :type="0" :index="true"></cooperateList>
      </div>
    </div>
    <div class="section-contact">
      <div class=" wow fadeInUp">
          <contactUs></contactUs>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import { WOW } from "wowjs";
import contactUs from "./../components/contactUs.vue";
import cooperateList from "./../components/cooperateList.vue";

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    cooperateList,
    contactUs
  },
  data() {
    return {
      showS1:"1",
      service1:[
        {
          id:1,
          name:"定义数据",
          content:"数据定义是战略级的"
        },
        {
          id:2,
          name:"定义业务",
          content:"定义业务包含业务间的勾稽算法和数据链路"
        },
        {
          id:3,
          name:"定义管理",
          content:"定义数据的处理规则和风控规则"
        },
        {
          id:4,
          name:"定义事件",
          content:"定义流程流转义触发条件"
        },
        {
          id:5,
          name:"定义翻译",
          content:"义翻译规则"
        },
        {
          id:6,
          name:"定义呈现",
          content:"定义呈现的角度,维度和可视化交互方式穿透和追溯方式"
        }
      ],
      service2:[
        {
          id:1,
          name:"分析建模",
          content:""
        },
        {
          id:2,
          name:"数据建模",
          content:""
        },
        {
          id:3,
          name:"智能核算",
          content:""
        },
        {
          id:4,
          name:"智能税务",
          content:""
        },
        {
          id:5,
          name:"业务建模",
          content:""
        },
        {
          id:6,
          name:"事件建模",
          content:""
        },
        {
          id:7,
          name:"管理建模",
          content:""
        }
      ],
      timer: "",
      timerNav: "",
      tab31Num: 0,
      tab32Num: 0,
      tab33Num: 0,
      tab41Num: 0,
      tab42Num: 0,
      tab43Num: 0,
      tab41: [
        { class: "icon41-1", name: "在线起草" },
        { class: "icon41-2", name: "在线审阅" },
        { class: "icon41-3", name: "自动生成" }
      ],
      tab32: [{ name: "绩效分配" }, { name: "经营分析" }],
      tab33: [{ name: "案件沉淀" }, { name: "经验沉淀" }],
      tab42: [
        { class: "icon42-1", name: "任务管理" },
        { class: "icon42-2", name: "计时管理" }
      ],
      tab43: [
        { class: "icon43-1", name: "团队组合" },
        { class: "icon43-2", name: "业务共享" }
      ],
      tab31: [
        { num: 0, id: 311, name: "利冲检索" },
        { num: 1, id: 312, name: "合同正本归档风控" },
        { num: 2, id: 313, name: "案件关键节点风控" }
      ],
      active2: "0",
      active21:"0",
      active3: "0",
      active4: "0",
      active5: "0",
      tabPosition: "left",
      // imgs: [
      //   {
      //     src:
      //       "https://t4.focus-img.cn/sh120x90sh/bet/res/cd38686e83b02b656f0041135ff19710.jpg",
      //     preview:
      //       "https://t4.focus-img.cn/sh120x90sh/bet/res/3b9a64a0c7c081caafb33f59628b2d0b.jpg"
      //   },
      // ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        offset: 0,
        live: false
      });
      wow.init();
    });
    // let tab1 = parseInt(this.active2, 10);
    // this.timer1 = setInterval(() => {
    //   tab1++;
    //   if (tab1 > 1) {
    //     tab1 = 0;
    //   }
    //   this.active2 = tab1.toString();
    // }, 5000);
  },
  beforeDestroy() {},
  methods: {
    changeImg(){
      this.showS1 = this.showS1==="1"?"2":"1";
    },
    classItem(value) {
      let obj = {numlis: true}
      obj["numlis"+`${value}`] = true
      return obj
  　},
    tclassItem(value) {
      let obj = {numlis: true}
      obj["tnumlis"+`${value}`] = true
      return obj
  　},
    tab31Active(num) {
      this.tab31Num = num;
    },
    tab41Active(num) {
      this.tab41Num = num;
    },
    tab32Active(num) {
      this.tab32Num = num;
    },
    tab33Active(num) {
      this.tab33Num = num;
    },
    tab42Active(num) {
      this.tab42Num = num;
    },
    tab43Active(num) {
      this.tab43Num = num;
    }
  }
};
</script>
<style lang="scss" scoped>
dd{
  margin:0;
}
.section-service{
  padding-bottom:60px;
  /deep/ .el-tabs__nav-wrap{
    overflow: inherit!important;
    height:50px;
  }
  .tabhead p{
    position: absolute;
    top: 55px;
    left: 77px;
    font-size: 20px;
    text-align: center;
  }
  /deep/ .el-tabs__content{
    margin-top:50px
  }
  /deep/ .el-tabs__item{
    padding-top:0!important;
  }
  /deep/ .el-tabs__item{
    height: 50px;
  }
}
/deep/ .el-tooltip__popper{
  font-size:18pxt;
}
.section-about{
  .inner-title{
    padding-bottom:10px;
  }
  .cent{
    padding-bottom:60px;
    // display:flex;
    // justify-content:space-between;
  }
  dl{
    display:inline-block;
    // flex:1;
    width:490px;
    height: 545px;
    border:1px solid transparent;
    border-radius:5px;
    margin:0 50px;
    cursor: pointer;
    .txt{
      margin-top:20px;
      h6{
        color:#0183de;
        border:1px solid #0183de;
        border-radius:5px;
        height:42px;
        line-height:42px;
        padding:0 10px;
        font-size:26px;
        display:inline-block;
        margin:0;
        font-weight:normal;
      }
      p{
        font-size:24px;
      }
    }
    &:hover{
    border:1px solid #0183de;
    }
  }
}
.seciton-advantage{
  background:#f1f6ff;
  .tag-imgs{
    margin-top:40px;
    display:flex;
    justify-content:space-between;
  }
  .inner-title{
    padding-top:40px
  }
  dl{
    flex:1;
    height: 545px;
    border:1px solid transparent;
    margin:0 20px;
    cursor: pointer;
  }
  .cent{
    padding-bottom:40px;
  }
  .img{
    height: 486px;
    border-radius:5px;
    .imgs{
      display:inline-block;
      margin:50px auto 0 auto
    }
  }
  .txts{
    margin-top:-20px;
    h6{
      width:245px;
      height:48px;
      line-height:48px;
      text-align:center;
      color:#fff;
      background:#0183de;
      border-radius:30px;
      padding:0 10px;
      font-size:26px;
      display:inline-block;
      margin:0;
      font-weight:normal;
    }
  }
  .blue-bg{
    .img{
      background:#e3effd;
    }
    h6{
        background:#0283df;
    }
  }
  .green-bg{
    .img{
      background:#e3f1f9;
    }
    h6{
        background:#0ba89d;
    }
  }
  .orange-bg{
    .img{
      background:#f1eff4;
    }
    h6{
        background:#ed7d31;
    }
  }
}
.section-cooperate{
  background:#f1f6ff;
  padding-bottom:80px;
  // .cent{
  //   width:1360px;
  // }
}
.service-tabs{
  position: relative;
  .shead{
    position: absolute;
    background:#fff;
    left:0;
    top:170px;
    margin:0;
    li{
      list-style:none;
      color:#333;
      font-size:22px;
      line-height:64px;
      background: #fff;
      position: relative;
      z-index: 10;
      cursor: pointer;
      &.active{
        font-size:24px;
        color: #0183de;
      }
    }
    &::before{
      content:"";
      width:1px;
      background: #000;
      height: 600px;
      position: absolute;
      top: -140px;
      left: 100px;
    }
  }
  .sbtn{
    position: absolute;
    top: 600px;
    right: 0;
    z-index: 10;
    width:1100px;
    text-align: right;
    &::before{
      content:"";
      width:800px;
      background: #000;
      height: 1px;
      position: absolute;
      top: 30px;
      left: 0;
    }
    .prev{
      background-position:0 0;
    }
    .next{
      background-position:-40px 0;
    }
    span{
      width:40px;
      height:40px;
      margin-right:30px;
      display: inline-block;
      cursor: pointer;
      background:url("~@/assets/images/home/service-icon.png") no-repeat #fff;
      &:hover{
        &.prev{
          background-position:0 -40px;
        }
        &.next{
          background-position:-40px -40px;
        }
      }
    }
  }
  .scent{
    position: absolute;
    width: 810px;
    height: 580px;
    right: 0;
    top:0;
  }
  .s-item{
    position: relative;
    .numlis {
      position: absolute;
      width: 80px;
      height:80px;
      border-radius:50%;
      background:#fff3ea;
      font-size:20px;
      display: inline-block;
      color:#ed7d31;
      font-weight:600;
      padding: 0 13px;
      padding-top: 16px;
      line-height: 26px;
      cursor: pointer;
    }
    .numlis1{
      background:#fff3ea;
      color:#ed7d31;
      top:248px;
      left:68px;
    }
    .numlis2{
      background:#0ba99d;
      color:#fff;
      top:100px;
      left:90px;
    }
    .numlis3{
      background:#f1f6ff;
      color:#0183de;
      top:40px;
      left:250px;
    }
    .numlis4{
      background:#fff3ea;
      color:#ed7d31;
      top:58px;
      left:424px;
    }
    .numlis5{
      background:#f1f6ff;
      color:#0183de;
      top:33px;
      left:572px;
    }
    .numlis6{
      background:#ecfbfa;
      color:#0ba99d;
      top:252px;
      left:688px;
    }
    .tnumlis1{
      background:#fff3ea;
      color:#ed7d31;
      top:248px;
      left:8px;
    }
    .tnumlis2{
      background:#ecfbfa;
      color:#0ba99d;
      top:100px;
      left:30px;
    }
    .tnumlis3{
      background:#f1f6ff;
      color:#0183de;
      top:40px;
      left:190px;
    }
    .tnumlis4{
      background:#fff3ea;
      color:#ed7d31;
      top:58px;
      left:344px;
    }
    .tnumlis5{
      background:#f1f6ff;
      color:#0183de;
      top:33px;
      left:512px;
    }
    .tnumlis6{
      background:#fff3ea;
      color:#ed7d31;
      top:120px;
      left:676px;
    }
    .tnumlis7{
      background:#ecfbfa;
      color:#0ba99d;
      top:252px;
      left:710px;
    }
  }
}
.home {
  color: #111;
}
.inner-title {
  font-weight: bold;
  color: #333333;
  line-height: 34px;
  padding: 60px 0 20px;
  position: relative;
  z-index: 5;
  h3{
    font-size: 30px;
    font-weight:bold;
  }
  p{
    font-size:22px;
    font-weight: normal;
  }
}
.swiper-wrapper {
  height: 700px;
}
.swiper-data {
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  position: relative;
  height: 700px;
  .home-video{
    margin-right:10px;
    &::before{
      content:"";
      font-size:0;
      width:0;
      height:0;
      border: 8px solid #fff;
      border-top-color:  transparent;
      border-right-color: transparent;
      border-bottom-color:  transparent;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}
.swiper-data1 {
  background: url("~@/assets/images/home/banner1-bg.png") no-repeat center;
  .swipertxt {
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: -570px;
    z-index: 10;
  }
  .imgs{
    visibility: visible;
    position: absolute;
    left: 50%;
    margin-left: 100px;
    top: 60px;
  }
  .maintit {
    color:#fff;
    font-size: 46px;
    font-weight: 300;
  }
  .subtit {
    font-size: 38px;
    color: #fff;
    margin: 30px 0;
  }
  .home-contact {
    width: 215px;
    height: 58px;
    line-height: 58px;
    color: #fff;
    font-size: 19px;
    text-align: center;
    font-weight: 300;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 80px;
  }
}
.swiper-data2 {
  background: #0f478f;
  background: linear-gradient(180deg, #2f9ecf 0%, #0f478f 100%);
  .imgs{    
    visibility: visible;
    position: absolute;
    left: 50%;
    margin-left: -720px;
    top: 60px;
  }
  .swipertxt {
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: 0;
    z-index: 10;
    text-align: right;
  }
  .maintit {
    color:#fff;
    font-size: 46px;
    font-weight: 300;
  }
  .subtit {
    font-size: 38px;
    color: #fff;
    margin: 30px 0;
  }
  .home-contact {
    width: 215px;
    height: 58px;
    line-height: 58px;
    color: #fff;
    font-size: 19px;
    text-align: center;
    font-weight: 300;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 80px;
  }
}
.info-notice {
  color: #939393;
  font-size: 12px;
  text-align: center;
  position: relative;
  z-index: 10;
}
.cent {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  z-index: 30;
}
.tabhead {
  h3 {
    font-size: 26px;
    margin: 0;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
}
/deep/ .el-tabs__content {
  overflow: inherit;
}
/deep/ .el-tabs__item {
  height: auto;
  line-height: 34px;
  padding: 20px 40px !important;
  outline: none;
  &:hover {
    color: #0183de;
  }
}
/deep/ .el-tabs__nav-wrap:after {
  background-color: #c9d8fd;
  background: linear-gradient(
    to right,
    rgba(201, 216, 253, 0.1) 0%,
    rgba(201, 216, 253, 0.5) 20%,
    rgba(201, 216, 253, 1) 60%,
    rgba(201, 216, 253, 0.5) 80%,
    rgba(201, 216, 253, 0.1) 100%
  );
}
/deep/ .el-tabs__item.is-active {
  color: #0183de;
  border-bottom: 2px solid #0183de;
}
/deep/ .el-tabs__nav {
  //  width:100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.section2 {
  /deep/ .el-tabs__nav {
    padding: 0 220px;
  }
}

/deep/ .el-tabs__active-bar {
  display: none;
}
/deep/ .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  outline: none;
  opacity: 1;
  background: none;
  border: 1px solid #fff;
}
/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}
/deep/ .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 30px;
}
/deep/
  .swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
}
.section-service{
  /deep/ .el-tabs__nav-scroll{
    overflow: inherit!important;
  }
  /deep/ .el-tabs__item.is-active .tabhead p{
    color:#333
  }
}
@media (max-width: 1600px) {
  .home .swiper-data .swiperbg {
    right: -200px;
  }
  .home .swiper-data2 {
    .swipertxt {
      padding-left: 20px;
    }
  }
}
@media (max-width: 1200px) {
  .home .swiper-data > div {
    width: 98%;
  }
  .home .swiper-data .swiperbg {
    right: -100px;
  }
}
@media (max-width: 750px) {
  .inner-title {
    font-size: 22px;
    line-height: 26px;
    padding-top: 30px;
    h3{
      font-size:24px;
    }
    p{
      font-size:20px;
    }
  }
  .swiper-wrapper {
    height:475px;
  }
  .swiper-data {
    height: 475px;
  }
  .swiper-data1{
    background:#0494e0;
    background: url("~@/assets/images/banner-home-app.jpg") no-repeat bottom right;
  }
  .swiper-data1{
    .imgs{
      left: 0;
      width: 80%;
      top: auto;
      bottom:20px;
      margin: 0;
      margin: 0 10%;
    }
  }
  .swiper-data1 .swipertxt {
    top:50px;
    left: 0;
    margin-left: 0;
    text-align: center;
  }
  .swiper-data1 .maintit {
    font-size: 30px;
  }
  .swiper-data1 .subtit {
    font-size: 20px;
    margin:15px 0;
  }
  .swiper-data1 .home-contact {
    height: 36px;
    line-height: 34px;
    width: 110px;
    font-size: 14px;
    margin-top: 17px;
  }
  .swiper-data2 .swipertxt {
    top: 54px;
    left: 0;
    margin-left: 0;
    text-align: center;
  }
  .swiper-data2 .maintit {
    font-size: 30px;
  }
  .swiper-data2 .subtit {
    font-size: 20px;
    margin:15px 0;
  }
  .swiper-data2 .home-contact {
    height: 36px;
    line-height: 34px;
    width: 110px;
    font-size: 14px;
    margin-top: 17px;
  }
  .swiper-data1 {
    background-size: contain;
  }
  .swiper-data2 {
    background-size: cover;
  }
  .swiper-data2 .imgs {
    left: 0;
    width: 90%;
    top: auto;
    bottom: 30px;
    margin: 0;
    margin: 0 5%;
  }
  .swiper-data .home-video:before{
    border-width: 5px;
  }
  .cent {
    width: 98%;
  }
  .section-cooperate .cent{
    width:98%;
  }
  .section-about{
    .inner-title{
      width:90%;
      margin: 0 auto;
    }
    dl{
      width: 50%;
      margin:0;
      height: auto;
      .txt{ 
        p{
        font-size:14px;
        }
        h6{
          font-size:20px;
        }
      }
      .imgs{
        width:100%;
      }
    }
  } 
  /deep/ .el-tabs__item{
    font-size:16px;
    font-weight: bold;
    padding:0!important;
    // line-height:66px;
  }
  /deep/ .el-tabs__nav{
    padding:0;
  }
  .tabhead h3{
    font-size:16px;
    font-weight: bold;
  }
  .headcent2{
    height:300px;
    img{
      width:100%;
    }
  }
  .service-tabs{
    .scent{
      width:100%;
      }
    .s-item {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20%;
      padding-top: 98px;
      span{
        position: inherit!important;
        top: auto!important;
        right: auto!important;
        left: auto!important;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .sbtn{
      width: 100%;
      top: 545px;
      &::before{
        width: 46%;
        top: 19px;
        left: 30px;
      }
    }
    .shead{
      top: -20px;
      left: -40px;
      &::before{
        left: 70px;
        top: -17px;
      }
      li{
        font-size:18px;
        line-height: 46px;
        &.active{
          font-size:20px;
        }
      }
    }
  } 
  .section-service{
    padding-bottom:0;
  }
  .headcent1 .imgs{
    width:100%;
    flex:none;
  }
  .headcent1 .imgs2{
    margin-top: -50px;
    margin-left: 20px;
  }
  .section-cooperate{
    padding-bottom: 50px;
  }
  .section-contact{
    padding-bottom: 30px;
  }
  .section-service /deep/ .el-tabs__content{
    margin-top: 90px;
  }
  .section-service .tabhead p{
    left: 15px;
    font-size: 14px;
  }
  .seciton-advantage{ 
    .tag-imgs{
      flex-wrap: wrap;
    }
    .img{ 
      height: auto;
      .imgs{
        width:100%;
        margin: 30px auto;
      }
    }
    dl{
      margin: 0 auto 30px;
      height: auto;
    }
  }
}
@media (max-width: 330px) {
  .service-tabs{
    .s-item {
      margin-left: 16%;
      padding-top: 104px;
      .numlis{
        width:76px;
        height:76px;
        padding-top: 11px;
        font-size:18px;
      }
    }
  }
}

</style>
