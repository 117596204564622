<!--
 * @Description: 官网菜单
 * @Author: 
 * @Date: 2021-03-11 13:59:54
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-11 10:39:08
-->
<template>
  <div class="menu">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-content"
      mode="horizontal"
      @select="handleSelect"
      :style="renderBackgroundStyle"
    >
      <div class="menu-center" :class="{ ismenu: isPhoneActive }">
        <img src="@/assets/images/logo-menu.png" class="menu-logo" />
        <div
          class="menu-phone"
          @click="phoneMenu"
          :class="{ cur: isPhoneActive }"
        ></div>
        <div class="menu-nav" :class="{ phoneMenu: isPhoneActive }">
          <el-menu-item index="Home">首页</el-menu-item>
          <el-menu-item index="Service">我们的实践</el-menu-item>
          <el-menu-item index="Details">方案演示</el-menu-item>
          <el-menu-item index="Case">数字化洞见</el-menu-item>
          <el-menu-item index="Cooperation">合作机构</el-menu-item>
          <el-menu-item index="trend">公司动态</el-menu-item>
          <el-menu-item index="About">关于我们</el-menu-item>
          <el-menu-item index="Join">加入我们</el-menu-item>
          <el-menu-item index="2" v-show="isPhone" @click="goLogin"
            >下载</el-menu-item
          >
        </div>
        <div class="menu-right">
          <div @click="goLogin">下载</div>
        </div>
      </div>
    </el-menu>
    <div class="submenu" v-if="activeIndex==='Service'||activeIndex==='ServiceTwo'">
      <div class="inner">
        <router-link :to="'Service'" :class="{cur:activeIndex==='Service'}">标准数字化方案</router-link>
        <router-link :to="{ name: 'ServiceTwo'}"  :class="{cur:activeIndex==='ServiceTwo'}">个性数字化方案</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Menu",
  beforeRouteEnter() {
    this.scrollDs();
  },
  computed: {
    renderBackgroundStyle() {
      // let trans = Math.min((this.scrollOffset / 800.0) * 255, 230);
      // trans = Number(trans.toFixed(0));
      // let hexString = trans.toString(16);
      return {
        // "background-color": "#fff" + hexString
        "background-color": "#fff"
      };
    }
  },
  data() {
    return {
      // 选中标签
      activeIndex: "Home",
      // 是否下载页
      isDownLoad: 0,
      isPhone: false,
      isPhoneActive: false,
      // 是否滚动到最顶上
      isScrollToTop: true,
      scrollOffset: 0
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollDs);
    this.isMobile();
  },
  watch: {
    "$route.name"(newName) {
      this.activeIndex = newName;
      this.isDownLoad = newName === "Download" ? 1 : 0;
    }
  },
  methods: {
    // 进制转换
    hexToString(str) {
      str = "" + str;
      var val = "";
      var arr = str.split(",");
      for (let i = 0; i < arr.length; i++) {
        val += arr[i].fromCharCode(i);
      }
      return val;
    },
    // 滚动回调
    scrollDs() {
      if (window.pageYOffset !== undefined) {
        this.isScrollToTop = window.pageYOffset === 0;
        this.scrollOffset = window.pageYOffset;
      } else {
        this.isScrollToTop = window.scrollTop === 0;
        this.scrollOffset = window.scrollTop;
      }
    },
    // 菜单
    handleSelect(index) {
      this.$router.push({ name: index });
      if (this.isPhone) {
        this.phoneMenu();
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.isPhone = !!flag;
    },
    goLogin() {
      this.$router.push({ name: "Download"})
    },
    phoneMenu() {
      this.isPhoneActive = !this.isPhoneActive;
    }
  }
};
</script>
<style lang="scss" scoped>
.submenu{
  background-color: rgba(0, 0, 0, 0.4);
  height:48px;
  .inner{
    width:1200px;
    text-align:left;
    font-size:14px;
    line-height:48px;
    margin:0 auto;
    padding-left:300px;
  }
  a{
    margin-right:30px;
    text-decoration: none;
    color:#fff;
    cursor:pointer;
  }
}
.cur{
  font-weight: bold;
}
.menu {
  height: 64px;
  box-shadow: 0 0 3px rgba(83, 104, 231, 0.6);
  .menu-nav {
    margin-top: 3px;
  }
  .el-menu-content {
    border-bottom: none;
    background-color: transparent;
    height: 62px;
    & /deep/ .el-menu-item:hover {
      background-color: transparent;
    }
    & /deep/ .el-menu-item:focus {
      background-color: transparent;
    }
  }
  .menu-center {
    // width: 80%;
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    height: 100%;
  }
  .menu-logo {
    width: 108px;
    height: 23px;
    flex: none;
    // margin-top: 5px;
    margin-right: 40px;
    img {
      width: 108px;
      height: 23px;
    }
  }
  .menu-right {
    display: flex;
    font-size: 12px;
    margin-left: 20px;
    > div {
      height: 30px;
      line-height: 30px;
      text-align: center;
      // border: 1px solid #333;
      color: #fff;
      background: #0183de;
      margin-right: 10px;
      // margin-top: 5px;
      cursor: pointer;
      padding: 0 18px;
      border-radius: 5px;
      // &:hover {
      //   background: rgba($color: #0183de, $alpha: 0.2);
      // }
    }
    // .active {
    //   background: rgba($color: #0183de, $alpha: 0.2);
    // }
  }
  .el-menu-item {
    padding: 0 2px;
    margin: 0 10px;
  }
  .el-menu--horizontal .el-menu-item {
    color: #333;
    font-size: 14px;
    height: 32px;
    line-height: 30px;
    display: inline-block;
    padding: 0 8px;
    &:hover{
      color:#0183de
    }
  }
  .el-menu--horizontal .is-active {
    color: #0183de;
    font-weight: bold;
    border: 1px solid #0183de;
    border-radius: 5px;
  }
}
.menu-phone {
  visibility: hidden;
  font-size: 14px;
  display: inline-block;
}
.ismenu {
  background: rgba(31, 34, 58, 0.95);
  // border-bottom: 1px solid #44455f !important;
  // height: 53px;
}
@media (max-width: 1200px) {
  .menu .menu-center {
    width: 100%;
    position: relative;
    // height:100%;
  }
  .menu .el-menu--horizontal .el-menu-item {
    width: 100%;
    margin: 0;
    text-align: center;
    height: 60px;
    line-height: 60px;
    color:#fff
  }
  .menu-phone {
    position: absolute;
    top: 14px;
    right: 10px;
    visibility: visible;
    background: url("~@/assets/images/menu-open.png");
    width: 24px;
    height: 24px;
    background-size: 100%;
    &.cur {
      background: url("~@/assets/images/menu-close.png");
      background-size: 100%;
    }
  }
  .menu .menu-nav {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
    background-color: rgba(31, 34, 58, 0.95);
    top: 59px;
    margin-top: 0;
  }
  .menu .el-menu--horizontal .is-active {
    color: #3160dc;
    border-bottom: 0;
    border:0;
  }
  .menu .phoneMenu {
    visibility: visible;
    height: auto;
    width: 100%;
  }
  .menu-right {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .menu .menu-logo {
    margin-left: 10px;
    width: 108px;
    height:25px;
    height: auto;
    margin-top: 0;
    img{
    width: 108px;
    height:25px;
    }
  }
  .submenu{
    .inner{
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
  }
}
</style>
